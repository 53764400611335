export const cards = [
  '專注聆聽',
  '保持中觀',
  '持守界限',
  '放下評斷',
  '活在當下',
  '當一面鏡子',
  '讓感受流動',
  '跳出框架',
  '與變化共存',
  '身心一體',
  '相信自己',
  '將錯就錯',
  '信任同伴',
  '美化空間',
  '釋放能量'
  // 其他卡片請在此添加
]